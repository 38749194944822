@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");
html{
  scroll-behavior: smooth;
  scroll-margin-top: -100px;
}
body {
  --primary: #12212e;
  --secondary: #f8a41a;
  --white: #ffffff;
  --gray: #e8eded;
  font-family: "Roboto", sans-serif;
  min-height: 100vh;
  scroll-margin-top: -100px;
}
body {
  --primary: #061136;
  --secondary: #f8861a;
  --white: #ffffff;
  --gray: #e8eded;
  font-family: "Roboto", sans-serif;
  min-height: 100vh;
}
h2 {
  text-align: center;
  text-transform: uppercase;
  color: var(--primary);
}
#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
