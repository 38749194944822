.parallax {
  height: 100vh;
  overflow: hidden;
  background-color: #3C3080;
  position: relative;
}
.parallax__image__container{
    width: 100vw;
    height: 100vh;
    position: absolute;
}

.parallax__image{
    width: 100%;
    height: 100%;
    object-fit: cover;
}