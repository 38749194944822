.comment {
  box-sizing: border-box;
  max-width: 450px;
  background: var(--white);
  border-radius: 1em;
  filter: drop-shadow(0 0 6px black);
  padding: 1em;
  margin: 1em;
  color: var(--primary);
  overflow: hidden;
}
.comment:nth-child(2n + 1) {
  color: var(--white);
  background: var(--primary);
}
