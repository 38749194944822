.gallery__heading {
  margin-top: 100px;
}
.gallery {
  background: linear-gradient(
        to top,
        rgba(255, 255, 255, 0.8) 10%,
        rgb(255, 255, 255)
      )
      center bottom / cover,
    url(../../assets/images/mountains.jpg);
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}
img {
  max-width: 100%;
}
.gallery__section {
  padding: 1rem;
  position: relative;
  text-align: center;
}

.gallery__section::after {
  position: absolute;
  content: "";
  width: calc(100% - 2rem);
  max-width: 1200px;
  height: 3px;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  background: var(--primary);
}
.gallery__section:last-child:after {
  display: none;
}

@media screen and (min-width: 1200px) {
}

.gallery__section__container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: auto;
}

.gallery__image__container {
  max-width: 300px;
  margin: 1rem;
  aspect-ratio: 1;
  border-radius: 1rem;
  overflow: hidden;
}

.gallery__image {
  width: 100%;
  height: 100%;
  transition: 0.5s;
  object-fit: cover;
}
.gallery__image:hover {
  transform: scale(1.05);
  z-index: 1;
}
