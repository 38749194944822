.about__certificate {
  width: calc(100% - 2em);
  max-width: 600px;
}

.about__author__name {
  max-width: calc(100% - 2em);
}
.about__author__name tspan {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.about {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 1rem;
  padding-bottom: 2rem;
  text-align: center;
}

.about__text {
  text-align: left;
  max-width: 600px;
  margin: auto;
}

@media screen and (min-width: 1200px) {
  .about__author__name {
    width: 100%;
    max-width: none;
  }
  .about {
    display: grid;
    gap: 2em;
    grid-template-columns: 1fr 1fr;
    padding-left: calc(50vw - 600px);
    padding-right: calc(50vw - 600px);
  }
  .about__info {
    justify-content: center;
    display: flex;
    flex-direction: column;
  }
  .about__image__container::before {
    content: "";
    position: absolute;
    left: 20px;
    right: -20px;
    top: 20px;
    z-index: -1;
    bottom: -20px;
    border: 3px solid var(--secondary);
  }
}

.about__image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.about h2 {
  text-align: left;
}

.about__image__container {
  margin: 0 auto;
  position: relative;
  max-width: 600px;
}
